<div id="containerLogin1">

    <div id="izqLogin">
        <div class="text-center">
            <h2>Información sobre canales de denuncia externos</h2>
            <hr>
            <div class="text-left">
                <p>
                    En cumplimiento de lo previsto en el artículo 9.1.g) de la Directiva (UE) 2019/1937, del Parlamento Europeo y del Consejo, de 23 de octubre de 2019, relativa a la protección de las personas que informen sobre infracciones del Derecho de la Unión, se informa
                    a los Usuarios de este Canal de Ética, Denuncias y Comunicaciones sobre los procedimientos de denuncia externa ante las autoridades públicas competentes.
                </p>
                <p>
                    Se entiende por “<span class="font-italic">denuncia externa</span>” toda comunicación, verbal o por escrito, de información sobre infracciones ante las autoridades competentes.
                </p>
                <p>
                    Este tipo de actuación supone supone para los denunciantes una vía alternativa o complementaria a la denuncia interna, así como a la obligación de la Empresa de facilitar información sobre los procedimientos de denuncia investigados a las autoridades
                    competentes que lo soliciten en virtud de sus competencias administrativas o sancionadoras.
                </p>
            </div>
        </div>
    </div>
</div>





<div id="containerLogin2">
    <br>
    <div class="text-left">
        <p>
            En aplicación de la referida Directiva Europea, está pendiente de desarrollo por las autoridades competentes de España el desarrollo de estos canales de denuncia externos, debiendo velar en todo caso porque:
        </p>
        <ul>
            <li type="a">
                se establezcan canales de denuncia externa independientes y autónomos para la recepción y el tratamiento de la información sobre infracciones;
            </li>
            <li type="a">
                con prontitud, y en cualquier caso en un plazo de siete días a partir de la recepción de la denuncia, acusen recibo de ella a menos que el denunciante solicite expresamente otra cosa o que la autoridad competente considere razonablemente que el acuse
                de recibo de la denuncia comprometería la protección de la identidad del denunciante;
            </li>
            <li type="a">
                sigan las denuncias diligentemente;
            </li>
            <li type="a">
                den respuesta al denunciante en un plazo razonable, no superior a tres meses, o a seis meses en casos debidamente justificados;
            </li>
            <li type="a">
                comuniquen al denunciante el resultado final de toda investigación desencadenada por la denuncia, de conformidad con los procedimientos previstos en el Derecho nacional;
            </li>
            <li type="a">
                transmitan en tiempo oportuno la información contenida en la denuncia a las instituciones, órganos u organismos competentes de la Unión, según corresponda, para que se siga investigando, cuando así esté previsto por el Derecho de la Unión o nacional.
            </li>
        </ul>
        <p>
            Se considerará que los <strong>canales de denuncia externa son independientes y autónomos</strong>, siempre que cumplan todos los criterios siguientes:
        </p>
        <ul>
            <li type="a">
                se diseñen, establezcan y gestionen de forma que se garantice la exhaustividad, integridad y confidencialidad de la información y se impida el acceso a ella al personal no autorizado de la autoridad competente;
            </li>
            <li type="a">
                permitan el almacenamiento duradero de información, de conformidad con el artículo 18, para que puedan realizarse nuevas investigaciones.
            </li>
        </ul>
        <p>
            Los canales de denuncia externa permitirán denunciar por escrito y verbalmente. La denuncia verbal será posible por vía telefónica o a través de otros sistemas de mensajería de voz y, previa solicitud del denunciante, por medio de una reunión presencial
            dentro de un plazo razonable.
        </p>
        <p>
            En este sentido, las autoridades competentes deberá publicar, en una sección separada, fácilmente identificable y accesible de sus sitios web, como mínimo la información siguiente:
        </p>
        <ul>
            <li type="a">
                las condiciones para poder acogerse a la protección en virtud de la Directiva (UE) 2019/1937, del Parlamento Europeo y del Consejo, de 23 de octubre de 2019, relativa a la protección de las personas que informen sobre infracciones del Derecho de la Unión
                ;
            </li>
            <li type="a">
                los datos de contacto para los canales de denuncia externa, en particular, las direcciones electrónica y postal y los números de teléfono para dichos canales, indicando si se graban las conversaciones telefónicas;
            </li>
            <li type="a">
                los procedimientos aplicables a la denuncia de infracciones, incluida la manera en que la autoridad competente puede solicitar al denunciante aclaraciones sobre la información comunicada o proporcionar información adicional, el plazo para dar respuesta
                al denunciante y el tipo y contenido de dicha respuesta;
            </li>
            <li type="a">
                el régimen de confidencialidad aplicable a las denuncias y, en particular, la información sobre el tratamiento de los datos de carácter personal de conformidad con lo dispuesto en el artículo 17 de la presente Directiva, los artículos 5 y 13 del Reglamento
                (UE) 2016/679, el artículo 13 de la Directiva (UE) 2016/680 y el artículo 15 del Reglamento (UE) 2018/1725, según corresponda;
            </li>
            <li type="a">
                la naturaleza del seguimiento que deba darse a las denuncias;
            </li>
            <li type="a">
                las vías de recurso y los procedimientos para la protección frente a represalias, y la disponibilidad de asesoramiento confidencial para las personas que contemplen denunciar;
            </li>
            <li type="a">
                una declaración en la que se expliquen claramente las condiciones en las que las personas que denuncien ante la autoridad competente están protegidas de incurrir en responsabilidad por una infracción de confidencialidad; y
            </li>
            <li type="a">
                los datos de contacto del centro de información o de la autoridad administrativa única independiente, en su caso.
            </li>
        </ul>
    </div>
    <div style="height: 75px;"></div>
</div>