<app-denunciante></app-denunciante>

<div id="pasos">
    <div id="headerPasos">
        <strong>Añadir detalles de la denuncia</strong>
        <p>Rellena con el ID de la denuncia que desea modificar.</p>
    </div>
    <div class="animate__animated animate__backInUp" style="margin-bottom: 40px;">
        <br>
        <div class="text-center">
            <h2>ID denuncia <input type="text" [formControl]="codigo" size="15" id="inputID">
                <button class="botonFlecha" type="submit" (click)="getDenuncia()"><i class="bi bi-forward-fill"></i></button>
            </h2>
            <hr>
        </div>
        <br><br>

        <div *ngIf="denuncia">
            <div *ngIf="denuncia.estado != 'resuelta'">

                <div id="div">
                    <div id="color">
                        <h6>¿Desea añadir implicantes?
                            <button class="btn" type="button" id="more" style="width: 100%;" (click)="toggleShow()" style="padding:0;">
                            <i class="bi bi-chevron-compact-down" style="padding:10px;"></i></button>
                        </h6>

                    </div>
                    <div class="text-center" id="open2" *ngIf="implicantes">
                        <br>
                        <table class="table table-striped" id="my-table">
                            <thead class="text-center">
                                <tr>
                                    <th id="asterisco">Nombre</th>
                                    <th id="asterisco">Cargo</th>
                                    <th id="asterisco">Hechos acontecidos</th>
                                    <th>Teléfono/Email</th>
                                    <th>Domicilio</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="text-left">
                                <tr class="p-3 mb-2 bg-light text-dark" *ngFor="let implicadoForm of implicadoFormArray.controls; let i = index" [formGroup]="implicadoForm">
                                    <td><input type="text" class="form-control" formControlName="nombre_implicado" required> </td>
                                    <td><input type="text" class="form-control" formControlName="cargo_implicado" required></td>
                                    <td><input type="text" class="form-control" formControlName="hechos_acontecidos_implicado" required></td>
                                    <td><input type="text" class="form-control" formControlName="contacto_implicado"></td>
                                    <td><input type="text" class="form-control" formControlName="domicilio_implicado"></td>
                                    <td><button type="button" (click)="eliminarImplicado(i)" id="addRowChild" class="btn btn-outline-danger">Quitar</button></td>
                                </tr>
                            </tbody>

                        </table>
                        <button type="button" (click)="agregarImplicado()" id="addRowChild" class="btn btn-outline-primary">Añadir implicante</button>
                        <!-- <button (click)="agregarImplicado()" type="submit" style="float:right" class="btn btn-outline-primary" id="addRowChild">Añadir
                        implicante</button> -->
                        <!-- <br> <br><br> -->
                    </div>
                </div>



                <div id="div">
                    <div id="color">
                        <h6> ¿Desea añadir testigos?
                            <button class="btn" type="button" id="more" style="width: 100%;" (click)="toggleT()" style="padding:0;">
                            <i class="bi bi-chevron-compact-down" style="padding:10px;"></i></button>
                        </h6>
                    </div>

                    <div *ngIf="testigos" class="text-center">
                        <br>
                        <table class="table table-striped" id="my-table">
                            <thead class="text-center">
                                <tr>
                                    <th id="asterisco">Nombre</th>
                                    <!-- <th>Cargo</th> -->
                                    <th id="asterisco">Hechos acontecidos</th>
                                    <th>Teléfono/Email</th>
                                    <th>Domicilio</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="text-left">
                                <tr class="p-3 mb-2 bg-light text-dark" *ngFor="let testigoForm of testigoFormArray.controls; let j = index" [formGroup]="testigoForm">
                                    <td><input type="text" class="form-control" formControlName="nombre_testigo" required></td>
                                    <!-- <td><input type="text" class="form-control" formControlName="cargo_testigo" required></td> -->
                                    <td><input type="text" class="form-control" formControlName="hechos_acontecidos_testigo" required></td>
                                    <td><input type="text" class="form-control" formControlName="contacto_testigo"></td>
                                    <td><input type="text" class="form-control" formControlName="domicilio_testigo"></td>
                                    <td><button type="button" (click)="eliminarTestigo(j)" id="addRowChild" class="btn btn-outline-danger">Quitar</button></td>
                                </tr>
                            </tbody>

                        </table>

                        <button type="button" (click)="agregarTestigo()" class="btn btn-outline-primary" id="addRowChild">Añadir testigo</button>
                        <!-- <button type="submit" style="float:right" class="btn btn-outline-primary" id="addRowChild">Añadir
                        testigo</button> -->
                        <!-- <br> <br><br> -->
                    </div>
                </div>


                <div id="div">
                    <div id="color">
                        <h6> ¿Desea añadir algún detalle?
                            <button class="btn" type="button" id="more" style="width: 100%;" (click)="toggleD()" style="padding:0;">
                            <i class="bi bi-chevron-compact-down" style="padding:10px;"></i></button>
                        </h6>
                    </div>
                    <div *ngIf="detalle">
                        <br>
                        <textarea [formControl]="detalles" id="detalles" name="detalles" class="form-control" rows="6" cols="155" required></textarea>
                    </div>
                </div>

                <div id="div">

                    <div id="color">
                        <h6> ¿Desea añadir fecha al suceso?
                            <button class="btn" type="button" id="more" style="width: 100%;" (click)="toggleF()" style="padding:0;">
                            <i class="bi bi-chevron-compact-down" style="padding:10px;"></i></button>
                        </h6>
                    </div>
                    <div *ngIf="fecha">
                        <br>
                        <div style="width:350px; margin-left: 40%; margin-right: 40%" *ngIf="!denuncia.fecha_suceso">
                            <input type="date" class="form-control" [formControl]="fecha_suceso">
                        </div>
                        <div style="width:350px; margin-left: 40%; margin-right: 40%" *ngIf="denuncia.fecha_suceso">
                            <p>Ya ha aportado la fecha del suceso</p>
                        </div>
                    </div>

                </div>


                <div id="div">
                    <label for="formFileSm" class="form-label">
                    <h6>¿DESEA ADJUNTAR ALGÚN DOCUMENTO ADICIONAL?</h6>
                </label>
                    <div class="text-center">
                        <br>
                        <input id="formFileSm" type="file" style="width:400px" (change)="fileChangeEvent($event)" multiple>
                        <br><br>
                        <small>Documentos PDF, vídeos, imágenes jpg o png.
                        <br>(Se puede añadir más de un archivo)</small>
                    </div>
                </div>

                <br><br>
                <button type="submit" class="btn btn-primary" style="float:right" (click)="onSubmit()">Confirmar cambios</button>
                <br><br>
            </div>

            <div class="text-center" *ngIf="denuncia.estado == 'resuelta'">
                <h5>Su denuncia ya ha sido resuelta, no es posible añadir pruebas</h5>
            </div>

        </div>


        <div class="text-center" *ngIf="noExiste">
            <h5>No hay ninguna denuncia asociada al ID indicado</h5>
        </div>
        <div class="text-center" *ngIf="testigoanadido">
            <h5>Se han añadido los testigos</h5>
        </div>
        <div class="text-center" *ngIf="implicadoanadido">
            <h5>Se han añadido los implicados</h5>
        </div>
        <div class="text-center" *ngIf="detalleanadido">
            <h5>Se han añadido los detalles indicados</h5>
        </div>
        <div class="text-center" *ngIf="archivoanadido">
            <h5>Se han añadido los archivos</h5>
        </div>
        <div class="text-center" *ngIf="fechaanadida">
            <h5>Se ha añadido la fecha del suceso</h5>
        </div>

    </div>

</div>