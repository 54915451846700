<div id="navbar">
    <div id="emojiDiv">
        <a routerLink="/index"><i class="bi bi-arrow-bar-left" id="emoji"></i></a>
    </div>
    <ul class="nav nav-pills nav-justified">
        <li class="nav-item" routerLinkActive="active">
            <p id="num">1</p>
            <a class="nav-link">Datos de la denuncia </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <p id="num">2</p>
            <a class="nav-link">Detalles de la denuncia</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <p id="num">3</p>
            <a class="nav-link">Datos personales</a>
        </li>

        <li class="nav-item" routerLinkActive="active">
            <p id="num">4</p>
            <a class="nav-link">Confirmación</a>
        </li>
    </ul>
</div>