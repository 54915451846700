<app-pasos></app-pasos>

<form id="pasos" [formGroup]="denuncia" (ngSubmit)="onSubmit()">
    <div id="headerPasos">
        <strong>Tipicación denuncia</strong>

        <p>Para facilitar la posterior gestión de su denuncia, ayúdenos a categorizarla lo mejor posible.</p>
    </div>
    <div id="bodyPasos">
        <div class="form-group mb-2">

            <div id="divDcha" style="padding-right:10%; width:30%">
                <p>¿Se acuerda del día del suceso? </p>
                <input type="date" class="form-control" formControlName="fecha_suceso">
                <!--
                    <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                        outsideDays="hidden">
                    </ngb-datepicker>

                    <ng-template #t let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                            (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                        </span>
                    </ng-template>
                    <div id="divDcha" style="padding-right:20%">
                <label>¿Se acuerda de la ubicación? </label>

                    <input type="text" class="form-control" id="lugar">
        
            </div>
                     -->
            </div>
            <div id="divIzq" style="padding-left:10%; width:65%">
                <p id="asterisco">Indique el tipo de relación que mantenía con dicha empresa </p>
                <select class="custom-select" id="inputGroupSelect01" formControlName="relacion" required>
                    <option selected disabled value="">Seleccione una opción</option>
                    <option value="Empleado">Empleado</option>
                    <option value="Cliente">Cliente</option>
                    <option value="Proveedor">Proveedor</option>
                    <option value="Contratista">Contratista</option>
                    <option value="Ex empleado">Ex empleado</option>
                    <option value="Otros">Otros</option>
                </select>

            </div>



        </div>
        <br><br><br><br><br> <br><br><br> <br><br>
        <div>
            <p id="asterisco">¿Cuál sería la categoría relacionada con la denuncia?</p>
            <select class="custom-select " formControlName="categoria" required>
                <option selected disabled value="">Seleccione una opción</option>
                <option value="Protección de los consumidores">Protección de los consumidores</option>
                <option value="Protección del medioambiente">Protección del medioambiente</option>
                <option value="Protección de los datos personales e intimidad">Protección de los datos personales e intimidad
                </option>
                <option value="Salud pública">Salud pública</option>
                <option value="Seguridad en productos">Seguridad en productos</option>
                <option value="Seguridad de los alimentos">Seguridad de los alimentos</option>
                <option value="Seguridad en el transporte">Seguridad en el transporte</option>
                <option value="Seguridad de las redes y sistemas de información">Seguridad de las redes y sistemas de información</option>
                <option value="Competencia">Competencia</option>
                <option value="Contratación pública">Contratación pública</option>
                <option value="Otros">Otros</option>
            </select>

        </div>




        <br><br><br><br>


        <div class="form-check">
            <input class="form-check-input" type="checkbox" [formControl]="aceptado1" required>
            <label class="form-check-label" for="invalidCheck">
                <span id="asterisco"> He leído los <a class="text-muted" target="_blank" routerLink="/terminos-uso" >Términos de uso</a> y los
                    acepto de acuerdo con lo dispuesto en dicho aviso. </span>
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" [formControl]="aceptado2" required>
            <label class="form-check-label" for="invalidCheck">
                <span id="asterisco"> <a class="text-muted" routerLink="/canales-externos" target="_blank" >Canales de denuncia externos.</a>
                </span>
            </label>
            <div class="invalid-feedback" *ngIf="!aceptado1 || !aceptado2">
                You must agree before submitting.
            </div>
        </div>
    </div>
    <br><br>
    <button [disabled]="!aceptado1.value || !aceptado2.value" type="submit" class="btn btn-primary" id="buttonDcha">Siguiente</button>
    <br><br>

</form>
<br>