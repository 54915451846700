import { Component, OnInit } from '@angular/core';
import { ServicioDenunciaService } from '../../../../services/servicio-denuncia.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  public actualizaciones;
  public actualizaciones_recientes = [];
  public colores = [];
  public fechas = [];

  constructor(private servicioDenuncia: ServicioDenunciaService) { }

  ngOnInit(): void {
    this.getActualizaciones();
  }

  getActualizaciones(){
    this.servicioDenuncia.getActualizaciones().subscribe( res => {

      this.actualizaciones = res;
      this.actualizaciones.sort((b,a) => a.fecha_actualizacion - b.fecha_actualizacion);

      for (let i = 0; i < this.actualizaciones.length; i++) {

        // Muestra las últimas 15 actualizaciones
        if (i == 20) {
          break;
        }

        if (this.actualizaciones[i].color === 'rojo'){
          this.colores[i] = 'lightpink';
        } else if (this.actualizaciones[i].color === 'verde'){
          this.colores[i] = 'rgb(169, 231, 169)';
        } else if (this.actualizaciones[i].color === 'amarillo'){
          this.colores[i] = 'rgb(255, 255, 130)';
        } else if (this.actualizaciones[i].color === 'azul'){
          this.colores[i] = 'lightskyblue';
        }

        this.fechas[i] = new Date( +this.actualizaciones[i].fecha_actualizacion ).toLocaleDateString();

        this.actualizaciones_recientes[i] = this.actualizaciones[i];

      }

    });
  }
}
