import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicioDenunciaService } from '../../../services/servicio-denuncia.service';

@Component({
  selector: 'app-cuatro',
  templateUrl: './cuatro.component.html',
  styleUrls: ['./cuatro.component.css'],
  providers: [ServicioDenunciaService]
})
export class CuatroComponent implements OnInit {

  // ---------------------------------------------------------------------------------
  // Variables

  // Variables a rellenar con elementos de la base de datos
  public denuncias = [];

  // Variables para los archivos a subir
  public archivo = [];
  public previsualizacion: Array<string> = [];

  // Formulario de la denuncia
  public denuncia = new FormGroup ({
    fecha_suceso: new FormControl(''),
    relacion: new FormControl(''),
    categoria: new FormControl(''),
    comentado: new FormControl(''),
    implicados: new FormControl(''),
    testigos: new FormControl(''),
    explicacion_suceso: new FormControl(''),
    anonima: new FormControl(true),
    nombre_denunciante: new FormControl(''),
    apellidos_denunciante: new FormControl(''),
    email_denunciante: new FormControl(''),
    telefono_denunciante: new FormControl(''),
    cargo_denunciante: new FormControl(''),
    provincia_denunciante: new FormControl(''),
    pais_denunciante: new FormControl(''),
    codigo_identificacion: new FormControl(''),
    clave_acceso: new FormControl(''),
  });

  // Array de formularios para implicados
  public implicadoFormArray = new FormArray([]);

  // Array de formularios para testigos
  public testigoFormArray = new FormArray([]);

  // Variable para confirmación de la clave de acceso
  public clave_acceso_confirmacion = new FormControl('');

  // Aceptación de términos
  public aceptado = new FormControl(false);

  // ------------------------------------------------------------------------------------
  // Constructor y funciones

  constructor(private fb: FormBuilder, private _router: Router, private servicioDenuncia: ServicioDenunciaService) {}


  // -------------------------------------------------------------------------------------
  // Guardar

  onSubmit() {
    // console.log(this.denuncia.value);
    // console.log(this.implicadoFormArray.value);
    // console.log(this.testigoFormArray.value);
    // console.log(this.archivo);

    console.log(this.denuncia.valid);

    if ((this.denuncia.get('clave_acceso').value === this.clave_acceso_confirmacion.value) && this.denuncia.valid) {

      this.servicioDenuncia.addDenuncia(this.denuncia.value).subscribe( result => {

        console.log('Denuncia subida');

        this.getDenuncias();
        
        this._router.navigateByUrl('/confirmacion');

        localStorage.removeItem('archivo');
        localStorage.removeItem('implicado');
        localStorage.removeItem('denuncia');
        localStorage.removeItem('testigo');

      }, error => {
        console.log(<any>error);
      });

    } else if (this.denuncia.get('clave_acceso').value !== this.clave_acceso_confirmacion.value) {
      window.alert('Las contraseñas debe coincidir');
    } else if (!this.denuncia.valid) {
      window.alert('Por favor, introduzca la contraseña con 8 carácteres');
    }

  }

  getDenuncias() {
    this.servicioDenuncia.getDenuncias().subscribe( (result: any) => {

      this.denuncias = result;

      // Buscamos la ultima id ya que es la de la denuncia que acabamos de subir anteriormente
      const id = this.denuncias[this.denuncias.length - 1].id_denuncia;

      this.sendEmail(id);

    }, error => {
      console.log(<any>error);
    });
  }

  addImplicado(id) {

    for (let i = 0; i < this.implicadoFormArray.length; i++) {

      this.implicadoFormArray.controls[i].get('idDenuncia').setValue(id);

      this.servicioDenuncia.addImplicado(this.implicadoFormArray.controls[i].value).subscribe(res => {
        console.log('Implicado subido');
      }, error => {
        console.log(<any>error);
      });

    }

  }

  addTestigo(id) {

    for (let i = 0; i < this.testigoFormArray.length; i++) {

      this.testigoFormArray.controls[i].get('idDenuncia').setValue(id);

      this.servicioDenuncia.addTestigo(this.testigoFormArray.controls[i].value).subscribe(res => {
        console.log('Testigo subido');
      }, error => {
        console.log(<any>error);
      });

    }

  }

  addArchivo(id){
    for (let i = 0; i < this.archivo.length; i++) {
      let json = JSON.stringify(this.archivo[i]);

      let archivodenuncias = {
        archivo: json,
        idDenuncia: id
      };

      this.servicioDenuncia.addArchivo(archivodenuncias).subscribe( result => {
        console.log('Archivos subidos');
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  }

  sendEmail(id) {

    this.servicioDenuncia.sendEmail(id).subscribe( res => {
      // console.log('Estado del email: ' + res);
      this.addImplicado(id);
      this.addTestigo(id);
      this.addArchivo(id);

    });

  }


  anterior() {

    // console.log(this.denuncia.value);
    // console.log(this.implicadoFormArray.value);
    // console.log(this.testigoFormArray.value);
    // console.log(this.archivo);

    localStorage.setItem("denuncia", JSON.stringify(this.denuncia.value));
    localStorage.setItem("implicado", JSON.stringify(this.implicadoFormArray.value));
    localStorage.setItem("testigo", JSON.stringify(this.testigoFormArray.value));
    // localStorage.setItem("archivo", JSON.stringify(this.archivo));
    this._router.navigate(["/tres"]);
  }


  // -------------------------------------------------------------------------------------------
  // Inicio

  ngOnInit(): void {
    // Recogemos los datos de anteriores formularios para la denuncia...
    this.denuncia.setValue(JSON.parse(localStorage.getItem("denuncia")));
    // console.log(this.denuncia.value);


    // Para los archivos
    this.archivo = JSON.parse(localStorage.getItem("archivo"));
    // console.log(this.archivo);

    // Para los implicados...
    let implicadosGuardados = JSON.parse(localStorage.getItem("implicado"));

    // console.log(implicadosGuardados);

    for (let i = 0; i < implicadosGuardados.length; i++) {

      let implicadoF = this.fb.group({
        idDenuncia: [implicadosGuardados[i].idDenuncia],
        nombre_implicado: [implicadosGuardados[i].nombre_implicado],
        cargo_implicado: [implicadosGuardados[i].cargo_implicado],
        hechos_acontecidos_implicado: [implicadosGuardados[i].hechos_acontecidos_implicado],
        contacto_implicado: [implicadosGuardados[i].contacto_implicado],
        domicilio_implicado: [implicadosGuardados[i].domicilio_implicado],
      });

      this.implicadoFormArray.push(implicadoF);
    }
    // console.log(this.implicadoFormArray.value);


    // Y para los testigos
    let testigosGuardados = JSON.parse(localStorage.getItem("testigo"));

    // console.log(testigosGuardados);

    if (this.denuncia.get('testigos').value != 0) {
      for (let i = 0; i < testigosGuardados.length; i++) {

        let testigoF = this.fb.group({
          idDenuncia: [testigosGuardados[i].idDenuncia],
          nombre_testigo: [testigosGuardados[i].nombre_testigo],
          cargo_testigo: [testigosGuardados[i].cargo_testigo],
          hechos_acontecidos_testigo: [testigosGuardados[i].hechos_acontecidos_testigo],
          contacto_testigo: [testigosGuardados[i].contacto_testigo],
          domicilio_testigo: [testigosGuardados[i].domicilio_testigo],
        });

        this.testigoFormArray.push(testigoF);
      }
    }

    // console.log(this.testigoFormArray.value);
  }

}
