<div id="loginGeneral">
	<div id="emojiDiv" class="emoji">
		<div style="height: 15px;"></div>
		<a routerLink="/login"><i class="bi bi-arrow-bar-left" id="emoji"
				style="color:rgba(19, 81, 117, 0.603); "></i></a>
	</div>
	<div id="containerLogin1" class="text-center">
	
		<div class="animate__animated animate__backInLeft" id="botonLogin">
			<h2> Ver estado de una denuncia</h2>
			<button type="button" routerLink="/denunciante" id="buttonIcon">
				<i class="bi bi-eye" style="font-size: 6em;"></i>
			</button>
		</div>
	
		<div class="animate__animated animate__backInRight" id="botonLogin">
			<h2> Registrar nueva denuncia</h2>
			<button type="button" routerLink="/uno" id="buttonIcon">
				<i class="bi bi-plus-circle" style="font-size: 6em;"></i>
			</button>
		</div>
	</div>
</div>