import { Component } from '@angular/core';
import {MDCTextField} from '@material/textfield';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CanalDenuncias';
}
