import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CuatroComponent } from './pages/pasos/cuatro/cuatro.component';
import { DosComponent } from './pages/pasos/dos/dos.component';
import { TresComponent } from './pages/pasos/tres/tres.component';
import { UnoComponent } from './pages/pasos/uno/uno.component';
import { LoginComponent } from './pages/login/login.component';
import { ConfirmacionComponent } from './pages/pasos/confirmacion/confirmacion.component';
import { ListadoComponent } from './pages/home/empresa/listado/listado.component';
import { InicioComponent } from './pages/home/empresa/inicio/inicio.component';
import { InicioDenuncianteComponent } from './pages/home/denunciante/inicio/inicio.component';
import { ExtrasComponent } from './pages/home/denunciante/extras/extras.component';
import { IndexComponent } from './pages/index/index.component';
import { AuthGuard } from './services/auth.guard';
import { TerminosUsoComponent } from './pages/terminos-uso/terminos-uso.component';
import { CanalesExternosComponent } from './pages/canales-externos/canales-externos.component';
import { ProteccionDatosComponent } from './pages/proteccion-datos/proteccion-datos.component';

const routes: Routes = [
  { path: 'index', component: IndexComponent },

  { path: 'login', component: LoginComponent },

  { path: 'denunciante', component: InicioDenuncianteComponent },
  { path: 'extras', component: ExtrasComponent },

  { path: 'home', component: InicioComponent, canActivate: [AuthGuard] },
  { path: 'listado', component: ListadoComponent, canActivate: [AuthGuard] },

  { path: 'uno', component: UnoComponent },
  { path: 'dos', component: DosComponent },
  { path: 'tres', component: TresComponent },
  // { path: 'cuatro', component: CuatroComponent },
  { path: 'confirmacion/:id', component: ConfirmacionComponent },

  { path: 'terminos-uso', component: TerminosUsoComponent },
  { path: 'canales-externos', component: CanalesExternosComponent },
  { path: 'proteccion-datos', component: ProteccionDatosComponent },

  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: '**', pathMatch: 'full', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
