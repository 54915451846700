import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ServicioDenunciaService } from '../../../../services/servicio-denuncia.service';

@Component({
  selector: 'app-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.css'],
})
export class ExtrasComponent implements OnInit {

  public testigoanadido = false;
  public implicadoanadido = false;
  public archivoanadido = false;
  public detalleanadido = false;
  public fechaanadida = false;

  public denuncia;
  public noExiste;
  public archivo = [];
  public archivoASubir;
  public previsualizacion: Array<string> = [];
  public detalles = new FormControl('');
  public fecha_suceso = new FormControl('');
  public id = new FormControl();
  public codigo = new FormControl();

  public implicadoFormArray = new FormArray([
    this.fb.group({
      idDenuncia: [''],
      nombre_implicado: ['', [Validators.required]],
      cargo_implicado: ['', [Validators.required]],
      hechos_acontecidos_implicado: ['', [Validators.required]],
      contacto_implicado: [''],
      domicilio_implicado: [''],
    })
  ]);

  public testigoFormArray = new FormArray([
    this.fb.group({
      idDenuncia: [''],
      nombre_testigo: ['', [Validators.required]],
      cargo_testigo: [''],
      hechos_acontecidos_testigo: ['', [Validators.required]],
      contacto_testigo: [''],
      domicilio_testigo: [''],
    })
  ]);


  implicantes: boolean = false; 
  toggleShow() {
    this.implicantes = !this.implicantes;
  }

  testigos: boolean = false; 
  toggleT() {
    this.testigos = !this.testigos;
  }

  detalle: boolean =false;
  toggleD() {
    this.detalle = !this.detalle;
  }

  fecha: boolean =false;
  toggleF() {
    this.fecha = !this.fecha;
  }

  constructor(private servicioDenuncia: ServicioDenunciaService, private fb: FormBuilder, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  getDenuncia(){

    this.testigoanadido = false;
    this.implicadoanadido = false;
    this.archivoanadido = false;
    this.detalleanadido = false;
    this.fechaanadida = false;

    this.servicioDenuncia.getDenunciaByCodigo(this.codigo.value).subscribe(res => {
      if (res) {

      this.noExiste = false;
      this.denuncia = res[0];
      // console.log(this.denuncia);
      this.id.setValue(this.denuncia.id_denuncia);
      } else {
        this.noExiste = true;
        this.denuncia = null;
      }
    }, error => {
      console.log(<any>error);
      this.noExiste = true;
      this.denuncia = null;
    });
  }

  // Funciones para añadir un elemento más al array de implicados/testigos
  agregarImplicado(){
    const implicadoForm = this.fb.group({
      idDenuncia: [''],
      nombre_implicado: ['', [Validators.required]],
      cargo_implicado: ['', [Validators.required]],
      hechos_acontecidos_implicado: ['', [Validators.required]],
      contacto_implicado: [''],
      domicilio_implicado: [''],
    });
    this.implicadoFormArray.push(implicadoForm);
  }

  agregarTestigo(){
    const testigoForm = this.fb.group({
      idDenuncia: [''],
      nombre_testigo: ['', [Validators.required]],
      cargo_testigo: [''],
      hechos_acontecidos_testigo: ['', [Validators.required]],
      contacto_testigo: [''],
      domicilio_testigo: [''],
    });
    this.testigoFormArray.push(testigoForm);
  }

  // Funciones para eleminar un elemento especifico de los arrays de formularios de implicados/testigos
  eliminarImplicado(i) {
    this.implicadoFormArray.removeAt(i);
  }

  eliminarTestigo(j) {
    this.testigoFormArray.removeAt(j);
  }


  // Para subir archivos a la API
  fileChangeEvent(fileInput: any) {
    this.archivoASubir = [];
    //contador para recorrer el array vacio y llenarlo (el array donde se guardan las imagenes)
    var contadorAyudaImagenes = 0;

    //rellenamos la variable con las imagenes que se acaban de enlazar
    for (let i = 0; i < fileInput.target.files.length; i++) {
      this.archivoASubir[i] = fileInput.target.files[i];
    }

    //recorremos este array y vamos leyendo imagen por imagen para ir previsualizandola
    this.archivoASubir.forEach(element => {
      this.extraerBase64(element).then((imagen: any) => {
        //guardamos la base de la imagen para previsualizarla
        this.previsualizacion[contadorAyudaImagenes] = imagen.base;
        //aumentamos el contador
        contadorAyudaImagenes++;
      });
    });
  }

  // Para la previsualización del archivo subido (solo imágenes) (desactivado)
  extraerBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();

      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };

    } catch (e) {
      return null;
    }

  })

  onSubmit() {
    // console.log(this.implicadoFormArray.value);
    // console.log(this.testigoFormArray.value);
    // console.log(this.detalles.value);
    // console.log(this.fecha_suceso.value);
    // console.log(this.archivoASubir);

    // console.log(this.testigoFormArray.valid);

    this.denuncia = null;

    if (this.testigoFormArray.valid && this.testigoFormArray.length > 0) {
      this.addTestigo(this.id.value);
    }

    if (this.implicadoFormArray.valid && this.implicadoFormArray.length > 0) {
      this.addImplicado(this.id.value);
    }

    if (this.archivoASubir) {
      this.subirArchivosAPI(this.id.value);
    }

    if (this.detalles.value.length > 0){
      this.addDetalle(this.id.value);
    }

    if (this.fecha_suceso.value) {
      this.addFecha(this.id.value);
    }

  }

  addImplicado(id) {

    for (let i = 0; i < this.implicadoFormArray.length; i++) {

      this.implicadoFormArray.controls[i].get('idDenuncia').setValue(id);

      this.servicioDenuncia.addImplicado(this.implicadoFormArray.controls[i].value).subscribe(res => {
        console.log('Implicado subido');

        let fecha = new Date().getTime();

        let actualizacion = {
          idDenuncia: id,
          fecha_actualizacion: fecha,
          actualizacion: `El denunciante ha añadido implicado(s) a la denuncia con id ${this.codigo.value}`,
          color: 'amarillo'
        };

        this.servicioDenuncia.addActualizacion(actualizacion).subscribe();

        this.implicadoanadido = true;

        this.implicadoFormArray = new FormArray([
          this.fb.group({
            idDenuncia: [''],
            nombre_implicado: ['', [Validators.required]],
            cargo_implicado: ['', [Validators.required]],
            hechos_acontecidos_implicado: ['', [Validators.required]],
            contacto_implicado: [''],
            domicilio_implicado: [''],
          })
        ]);

      }, error => {
        console.log(<any>error);
      });

    }

  }

  addTestigo(id) {

    for (let i = 0; i < this.testigoFormArray.length; i++) {

      this.testigoFormArray.controls[i].get('idDenuncia').setValue(id);

      this.servicioDenuncia.addTestigo(this.testigoFormArray.controls[i].value).subscribe(res => {
        console.log('Testigo subido');

        let fecha = new Date().getTime();

        let actualizacion = {
          idDenuncia: id,
          fecha_actualizacion: fecha,
          actualizacion: `El denunciante ha añadido testigo(s) a la denuncia con id ${this.codigo.value}`,
          color: 'amarillo'
        };
  
        this.servicioDenuncia.addActualizacion(actualizacion).subscribe();

        this.testigoanadido = true;

        this.testigoFormArray = new FormArray([
          this.fb.group({
            idDenuncia: [''],
            nombre_testigo: ['', [Validators.required]],
            cargo_testigo: [''],
            hechos_acontecidos_testigo: ['', [Validators.required]],
            contacto_testigo: [''],
            domicilio_testigo: [''],
          })
        ]);

      }, error => {
        console.log(<any>error);
      });

    }
  }

  addDetalle(id){

    let fecha = new Date().getTime();

    let detalleasubir = {
      idDenuncia: id,
      detalle: this.detalles.value,
      fecha_detalle: fecha
    };

    this.servicioDenuncia.addDetalle(detalleasubir).subscribe( res => {
      console.log('Detalle subido');

      let fecha = new Date().getTime();

      let actualizacion = {
        idDenuncia: id,
        fecha_actualizacion: fecha,
        actualizacion: `El denunciante ha añadido detalles a la denuncia con id ${this.codigo.value}`,
        color: 'amarillo'
      };
  
      this.servicioDenuncia.addActualizacion(actualizacion).subscribe();

      this.detalleanadido = true;
      this.detalles.setValue('');
    },
    error => {
      console.log(<any>error);
    });
  }

  addFecha(id){

    let fechaacambiar = {
      id_denuncia: id,
      fecha_suceso: this.fecha_suceso.value
    };

    this.servicioDenuncia.updateDenuncia(fechaacambiar).subscribe( res => {
      console.log('Fecha cambiada');

      let fecha = new Date().getTime();

      let actualizacion = {
        idDenuncia: id,
        fecha_actualizacion: fecha,
        actualizacion: `El denunciante ha añadido fecha del suceso a la denuncia con id ${this.codigo.value}`,
        color: 'amarillo'
      };

      this.servicioDenuncia.addActualizacion(actualizacion).subscribe();

      this.fecha_suceso.setValue('');
      this.fechaanadida = true;
    },
    error => {
      console.log(<any>error);
    });

  }

  subirArchivosAPI(id) {
    this.servicioDenuncia.makeFileRequest(this.archivoASubir).subscribe(
      result => {
        //en caso de existir las subimos a la API y procedemos a guardar el pedido
        this.archivo = result;
        this.addArchivo(id);
      }, error => {
        console.log(error);
      }
    );
  }

  addArchivo(id){

    let contador = 0;
    let fecha = new Date().getTime();

    for (let i = 0; i < this.archivo.length; i++) {
      let json = JSON.stringify(this.archivo[i]);

      let archivodenuncias = {
        archivo: json,
        idDenuncia: id,
        fecha_archivo: fecha,
        resolucion: 0
      };

      //guardamos las imagenes para mobleku
      this.servicioDenuncia.addArchivo(archivodenuncias).subscribe( result => {
        console.log('Archivos subidos');
        this.archivoanadido = true;
        this.archivoASubir = null;
        contador++;
        },
        error => {
          console.log(<any>error);
        }
      );
    }

    setTimeout(() => {

      if (contador > 0){
        let actualizacion = {
          idDenuncia: id,
          fecha_actualizacion: fecha,
          actualizacion: `El denunciante ha añadido ${contador} archivo(s) a la denuncia con id ${this.codigo.value}`,
          color: 'amarillo'
        };

        this.servicioDenuncia.addActualizacion(actualizacion).subscribe();
      }
    }, 2000);

  }

}
