import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServicioDenunciaService } from '../../../services/servicio-denuncia.service';

@Component({
  selector: 'app-dos',
  templateUrl: './dos.component.html',
  styleUrls: ['./dos.component.css'],
  providers: [ServicioDenunciaService]
})
export class DosComponent implements OnInit {

  // -------------------------------------------------------------------------------------------------
  // Variables

  // Variables para los archivos a subir
  public archivo = [];
  public archivoASubir = [];
  public previsualizacion: Array<string> = [];

  // Formulario de la denuncia
  public denuncia = new FormGroup ({
    fecha_denuncia: new FormControl(''),
    estado: new FormControl('recibida'),
    fecha_suceso: new FormControl(''),
    relacion: new FormControl(''),
    categoria: new FormControl(''),
    comentado: new FormControl(''),
    implicados: new FormControl(''),
    testigos: new FormControl(''),
    explicacion_suceso: new FormControl(''),
    anonima: new FormControl(true),
    nombre_denunciante: new FormControl(''),
    apellidos_denunciante: new FormControl(''),
    email_denunciante: new FormControl(''),
    telefono_denunciante: new FormControl(''),
    cargo_denunciante: new FormControl(''),
    provincia_denunciante: new FormControl(''),
    pais_denunciante: new FormControl(''),
    codigo_identificacion: new FormControl(''),
    clave_acceso: new FormControl(''),
  });

  // Array de formularios para implicados
  public implicadoFormArray = new FormArray([
    this.fb.group({
      idDenuncia: [''],
      nombre_implicado: [''],
      cargo_implicado: [''],
      hechos_acontecidos_implicado: [''],
      contacto_implicado: [''],
      domicilio_implicado: [''],
    })
  ]);

  // Array de formularios para testigos
  public testigoFormArray = new FormArray([
    this.fb.group({
      idDenuncia: [''],
      nombre_testigo: [''],
      cargo_testigo: [''],
      hechos_acontecidos_testigo: [''],
      contacto_testigo: [''],
      domicilio_testigo: [''],
    })
  ]);


  // ----------------------------------------------------------------------------------------------
  // Constructor y funciones
  constructor(private _router: Router, private _service: ServicioDenunciaService,
    private fb: FormBuilder, private sanitizer: DomSanitizer) { }

  // Validadores para los campos necesarios de los formularios
  get explicacionSucesoNoValido() {
    return this.denuncia.get('explicacion_suceso').invalid && this.denuncia.get('explicacion_suceso').touched;
  }

  // get cargoImplicadoNoValido() {
  //   return this.implicadoFormArray.get('cargo_implicado').invalid && this.implicadoFormArray.get('cargo_implicado').touched;
  // }


  // Funciones para añadir un elemento más al array de implicados/testigos
  agregarImplicado(){
    const implicadoForm = this.fb.group({
      idDenuncia: [''],
      nombre_implicado: [''],
      cargo_implicado: [''],
      hechos_acontecidos_implicado: [''],
      contacto_implicado: [''],
      domicilio_implicado: [''],
    });
    this.implicadoFormArray.push(implicadoForm);
  }

  agregarTestigo(){
    const testigoForm = this.fb.group({
      idDenuncia: [''],
      nombre_testigo: [''],
      cargo_testigo: [''],
      hechos_acontecidos_testigo: [''],
      contacto_testigo: [''],
      domicilio_testigo: [''],
    });
    this.testigoFormArray.push(testigoForm);
  }

  // Funciones para eleminar un elemento especifico de los arrays de formularios de implicados/testigos
  eliminarImplicado(i) {
    this.implicadoFormArray.removeAt(i);
  }

  eliminarTestigo(j) {
    this.testigoFormArray.removeAt(j);
  }


  // Para subir archivos a la API
  fileChangeEvent(fileInput: any) {
    this.archivoASubir = [];
    //contador para recorrer el array vacio y llenarlo (el array donde se guardan las imagenes)
    var contadorAyudaImagenes = 0;

    //rellenamos la variable con las imagenes que se acaban de enlazar
    for (let i = 0; i < fileInput.target.files.length; i++) {
      this.archivoASubir[i] = fileInput.target.files[i];
    }

    //recorremos este array y vamos leyendo imagen por imagen para ir previsualizandola
    this.archivoASubir.forEach(element => {
      this.extraerBase64(element).then((imagen: any) => {
        //guardamos la base de la imagen para previsualizarla
        this.previsualizacion[contadorAyudaImagenes] = imagen.base;
        //aumentamos el contador
        contadorAyudaImagenes++;
      });
    });
  }

  // Para la previsualización del archivo subido (solo imágenes) (desactivado)
  extraerBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();

      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };

    } catch (e) {
      return null;
    }

  })


  // -------------------------------------------------------------------------------------------
  // Guardar

  onSubmit() {

    // console.log(this.denuncia.value);
    // console.log(this.implicadoFormArray.value);
    // console.log(this.testigoFormArray.value);
    // console.log(this.archivo);

    // console.log(this.denuncia.valid);
    // console.log(this.denuncia.get('testigos').value);
    // console.log(this.implicadoFormArray.valid);
    // console.log(this.testigoFormArray.valid);


    if ( this.denuncia.valid && (this.implicadoFormArray.valid && this.implicadoFormArray.length > 0) && (this.denuncia.get('testigos').value==0 || (this.testigoFormArray.valid && this.testigoFormArray.length > 0) )) {

      if (this.archivoASubir.length > 0) {
        this.subirArchivosAPI();
      } else {
        localStorage.setItem("denuncia", JSON.stringify(this.denuncia.value));
        localStorage.setItem("implicado", JSON.stringify(this.implicadoFormArray.value));
        localStorage.setItem("testigo", JSON.stringify(this.testigoFormArray.value));
        // localStorage.setItem("archivo", JSON.stringify(this.archivo));
        this._router.navigate(["/tres"]);
      }

    } else {
      window.alert('Por favor, introduzca los campos obligatorios');
    }

  }

  // Subir Imágenes a la carpeta updates de la API
  subirArchivosAPI() {
    this._service.makeFileRequest(this.archivoASubir).subscribe(
      result => {
        //en caso de existir las subimos a la API y procedemos a guardar el pedido
        this.archivo = result;
        localStorage.setItem("denuncia", JSON.stringify(this.denuncia.value));
        localStorage.setItem("implicado", JSON.stringify(this.implicadoFormArray.value));
        localStorage.setItem("testigo", JSON.stringify(this.testigoFormArray.value));
        localStorage.setItem("archivo", JSON.stringify(this.archivo));
        this._router.navigate(["/tres"]);
      }, error => {
        console.log(error);
      }
    );
  }

  anterior() {

    // console.log(this.denuncia.value);
    // console.log(this.implicadoFormArray.value);
    // console.log(this.testigoFormArray.value);
    // console.log(this.archivo);

    localStorage.setItem("denuncia", JSON.stringify(this.denuncia.value));
    localStorage.setItem("implicado", JSON.stringify(this.implicadoFormArray.value));
    localStorage.setItem("testigo", JSON.stringify(this.testigoFormArray.value));
    // localStorage.setItem("archivo", JSON.stringify(this.archivo));
    this._router.navigate(["/uno"]);
  }


  // --------------------------------------------------------------------------------------
  // Inicio

  ngOnInit(): void {
    // Recogemos los datos de anteriores formularios para la denuncia...
    this.denuncia.setValue(JSON.parse(localStorage.getItem("denuncia")));
    // console.log(this.denuncia.value);

    // Para los archivos
    this.archivo = JSON.parse(localStorage.getItem("archivo"));
    // console.log(this.archivo);

    // Para los implicados...
    let implicadosGuardados = JSON.parse(localStorage.getItem("implicado"));

    // console.log(implicadosGuardados);

    if (implicadosGuardados) {
      this.implicadoFormArray.removeAt(0);
      for (let i = 0; i < implicadosGuardados.length; i++) {

        let implicadoF = this.fb.group({
          idDenuncia: [implicadosGuardados[i].idDenuncia],
          nombre_implicado: [implicadosGuardados[i].nombre_implicado],
          cargo_implicado: [implicadosGuardados[i].cargo_implicado],
          hechos_acontecidos_implicado: [implicadosGuardados[i].hechos_acontecidos_implicado],
          contacto_implicado: [implicadosGuardados[i].contacto_implicado],
          domicilio_implicado: [implicadosGuardados[i].domicilio_implicado],
        });

        this.implicadoFormArray.push(implicadoF);
      }
    }
    // console.log(this.implicadoFormArray.value);


    // Y para los testigos
    let testigosGuardados = JSON.parse(localStorage.getItem("testigo"));

    // console.log(testigosGuardados);

    if (testigosGuardados) {
      this.testigoFormArray.removeAt(0);
      for (let i = 0; i < testigosGuardados.length; i++) {

        let testigoF = this.fb.group({
          idDenuncia: [testigosGuardados[i].idDenuncia],
          nombre_testigo: [testigosGuardados[i].nombre_testigo],
          cargo_testigo: [testigosGuardados[i].cargo_testigo],
          hechos_acontecidos_testigo: [testigosGuardados[i].hechos_acontecidos_testigo],
          contacto_testigo: [testigosGuardados[i].contacto_testigo],
          domicilio_testigo: [testigosGuardados[i].domicilio_testigo],
        });

        this.testigoFormArray.push(testigoF);
      }
    }
    // console.log(this.testigoFormArray.value);

  }

}

