<app-pasos></app-pasos>
<form id="pasos" [formGroup]="denuncia" (ngSubmit)="onSubmit()">
    <div id="headerPasos">
        <strong>Información de detalle</strong>

        <p>Describa con el máximo detalle el hecho que desea denunciar, detallando la máxima información y adjuntando, si las hay, pruebas. </p>
    </div>

    <div id="bodyPasos">
        <div>
            <p id="asterisco">¿Ha comentado este hecho con algún miembro de la dirección?</p>
            <input type="radio" value="Sí" formControlName="comentado" required><label>Sí</label>
            <br>
            <input type="radio" value="No" formControlName="comentado" required><label>No</label>
            <br>
            <input type="radio" value="No lo recuerda" formControlName="comentado" required><label>No lo recuerdo</label>
        </div>
        <br>

        <div>
            <p id="asterisco">¿Cree que alguna de las siguientes personas está implicada?</p>
            <select class="custom-select" formControlName="implicados">
                <option selected disabled value="">Seleccione una opción</option>
                <option value="Delegado de Protección de Datos">Delegado de Protección de Datos</option>
                <option value="Delegado de Prevención de Riesgos Laborales">Delegado de Prevención de Riesgos Laborales</option>
                <option value="Director de Compras">Director de Compras</option>
                <option value="Director de Personal">Director de Personal</option>
                <option value="Director de RRHH">Director de RRHH</option>
                <option value="Director de Servicios Legales">Director de Servicios Legales</option>
                <option value="Director de Sistemas de Información">Director de Sistemas de Información</option>
                <option value="Director Financiero">Director Financiero</option>
                <option value="Director General">Director General</option>
                <option value="Resposables de auditoría Interna y de Cumplimiento">Resposables de auditoría Interna y de Cumplimiento</option>
                <option value="Otras">Ninguna de las anteriores</option>
            </select>
        </div>
        <br><br> <br><br>

        <div class="text-center">
            <p>Indique la persona o personas implicadas en dicha denuncia</p>
            <table class="table table-striped">
                <thead class="text-center">
                    <tr>
                        <th id="asterisco">Nombre</th>
                        <th id="asterisco">Cargo</th>
                        <th id="asterisco">Hechos acontecidos</th>
                        <th>Teléfono/Email</th>
                        <th>Domicilio</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="text-left">
                    <tr class="p-3 mb-2 bg-light text-dark" *ngFor="let implicadoForm of implicadoFormArray.controls; let i = index" [formGroup]="implicadoForm">
                        <td><input type="text" class="form-control" formControlName="nombre_implicado" required> </td>
                        <td><input type="text" class="form-control" formControlName="cargo_implicado" required></td>
                        <td><input type="text" class="form-control" formControlName="hechos_acontecidos_implicado" required></td>
                        <td><input type="text" class="form-control" formControlName="contacto_implicado"></td>
                        <td><input type="text" class="form-control" formControlName="domicilio_implicado"></td>
                        <td><button type="button" (click)="eliminarImplicado(i)" id="addRowChild" class="btn btn-outline-danger">Quitar</button></td>
                    </tr>
                </tbody>
            </table>
            <button type="button" (click)="agregarImplicado()" id="addRowChild" class="btn btn-outline-primary">Añadir implicante</button>
        </div>
        <br><br>

        <div class="text-center">
            <label class="testigosFuente">¿Hay testigos?</label><br>
            <input type="radio" value="1" formControlName="testigos" required> Sí
            <input type="radio" value="0" formControlName="testigos" required> No
            <!-- <input type="checkbox" [checked]="valueT === 1" (click)="valueT = 1" required> Sí
            <input type="checkbox" [checked]="valueT === 0" (click)="valueT = 0" required> No -->
            <!-- <input type="checkbox" name="myCheckboxF" [checked]="valueT === 'yes'" (click)="valueT = 'yes'" formControlName="testigo" required> Sí
            <input type="checkbox" name="myCheckboxF" [checked]="valueT === 'no'" (click)="valueT = 'no'" formControlName="testigo" required>No -->
            <br>
            <div *ngIf="denuncia.get('testigos').value == 1">
                <!-- <div *ngIf="valueT === 1"> -->
                <table class="table table-striped" id="my-table">
                    <thead class="text-center">
                        <tr>
                            <th id="asterisco">Nombre</th>
                            <!-- <th>Cargo</th> -->
                            <th id="asterisco">Hechos acontecidos</th>
                            <th>Teléfono/Email</th>
                            <th>Domicilio</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="text-left">
                        <tr class="p-3 mb-2 bg-light text-dark" *ngFor="let testigoForm of testigoFormArray.controls; let j = index" [formGroup]="testigoForm">
                            <td><input type="text" class="form-control" formControlName="nombre_testigo" required></td>
                            <!-- <td><input type="text" class="form-control" formControlName="cargo_testigo" required></td> -->
                            <td><input type="text" class="form-control" formControlName="hechos_acontecidos_testigo" required></td>
                            <td><input type="text" class="form-control" formControlName="contacto_testigo"></td>
                            <td><input type="text" class="form-control" formControlName="domicilio_testigo"></td>
                            <td><button type="button" (click)="eliminarTestigo(j)" id="addRowChild" class="btn btn-outline-danger">Quitar</button></td>
                        </tr>
                    </tbody>

                </table>

                <button type="button" (click)="agregarTestigo()" class="btn btn-outline-primary" id="addRowChild">Añadir testigo</button>
            </div>
        </div>
        <br><br>

        <div class="text-center">
            <p id="asterisco">Explíque con detalle dicho suceso </p>
            <textarea id="detalles" [class.is-invalid]="explicacionSucesoNoValido" formControlName="explicacion_suceso" class="form-control" rows="6" cols="155" required></textarea>
            <small *ngIf="explicacionSucesoNoValido" class="text-danger">
                Por favor, rellene el campo
            </small>
        </div>

        <br><br>

        <div class="mb-3" class="text-center">
            <label for="formFileSm" class="form-label">¿Desea adjuntar algún documento adicional?</label>
            <br>
            <input id="formFileSm" type="file" (change)="fileChangeEvent($event)" multiple>
            <!-- <div *ngIf="previsualizacion">
                <img [src]="visualizar" alt="" class="previsualizar ml-2 mb-2" *ngFor="let visualizar of previsualizacion">
            </div> -->
            <br><br>
            <small>Documentos PDF, vídeos, imágenes jpg o png.
                <br>(Se puede añadir más de un archivo)</small>
        </div>

    </div>
    <br><br>
    <button class="btn btn-primary" (click)="anterior()">Anterior</button>
    <!-- <button type="submit" class="btn btn-primary" routerLink="/uno">Anterior</button> -->

    <input type="submit" class="btn btn-primary" id="buttonDcha" value="Siguiente">
    <!-- <input type="submit" class="btn btn-primary" id="buttonDcha" routerLink="/tres" value="Siguiente"> -->

</form>
<br>