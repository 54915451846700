import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServicioDenunciaService } from './servicio-denuncia.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private servicioDenuncia: ServicioDenunciaService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(resolve => {
      let token = localStorage.getItem('userTokenDenuncias');
      this.servicioDenuncia.checkToken(token).subscribe((user: any) => {
        if (user.Result == 'OK') {
          // console.log("##### User Guard: auth = true");
          resolve(true);
        } else {
          // console.log("##### User Guard: auth = false");
          // console.log('User is not logged in');
          this.router.navigate(['login']);
          // this.router.navigate(['login'], {
          //   queryParams: {
          //     redirectUrl: state.url
          //   }
          // });
          resolve(false);
        }
      });
    });
  }

}
