import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proteccion-datos',
  templateUrl: './proteccion-datos.component.html',
  styleUrls: ['./proteccion-datos.component.css']
})
export class ProteccionDatosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
