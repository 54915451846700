import { Component, OnInit } from '@angular/core';
import { DenunciaModel } from 'src/app/models/denuncia.model/denuncia.model';

@Component({
  selector: 'app-pasos',
  templateUrl: './pasos.component.html',
  styleUrls: ['./pasos.component.css']
})
export class PasosComponent implements OnInit {

  denuncia : DenunciaModel = new DenunciaModel();

  constructor() { }

  ngOnInit(): void {
  }


  nuevaDenuncia(){


  }

  getDenunciaUsuario( id: number ){

  }

}
