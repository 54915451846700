import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terminos-uso',
  templateUrl: './terminos-uso.component.html',
  styleUrls: ['./terminos-uso.component.css']
})
export class TerminosUsoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
