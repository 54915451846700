<div id="containerLogin1">

    <div id="izqLogin">
        <div class="text-center">
            <h2>Política de protección de datos y consentimiento</h2>
            <hr>
            <div class="text-left">
                <p>
                    Para poder recibir y tramitar tu denuncia, debes confirmar que has leído este Aviso de Privacidad. En caso de que no aceptes las condiciones conforme a lo dispuesto en el aviso, no podrás realizar la denuncia.
                </p>
                <p>
                    En este sentido y en cumplimiento de lo dispuesto en el artículo 13 del Reglamento (UE) 2016/679 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (Reglamento
                    General de Protección de Datos o RGPD) y artículo 11 de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD GDD) a continuación, se expone la información detallada
                    sobre el tratamiento de datos de carácter personal en el marco del Canal de Ética, Comunicación y Denuncias.
                </p>
                <p>
                    Este canal de denuncias está gestionado por Química Industrial Mediterránea, S.L.U., con CIF B29225687, y domicilio social en Calle Rosa de los Vientos, 75, 29006, Málaga, Andalucía, España (en adelante, “la Empresa”).
                </p>
                <p>
                    Química Industrial Mediterránea, S.L.U. garantiza la seguridad y la confidencialidad de los datos recopilados para la finalidad del tratamiento, de conformidad con el presente Aviso de Privacidad.
                </p>
            </div>
        </div>
    </div>
</div>





<div id="containerLogin2">
    <br>
    <div class="text-left">
        <p>A continuación, te mostramos la información básica sobre como tratamos tus datos personales:</p>
        <br>
        <table class="table">
            <tbody>
                <tr>
                    <td>
                        <strong>Responsable de tratamiento</strong>
                    </td>
                    <td>
                        Química Industrial Mediterránea, S.L.U., con CIF B29225687, y domicilio social en Calle Rosa de los Vientos, 75, 29006, Málaga, Andalucía, España (en adelante, “la Empresa”).
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Delegado de protección de datos</strong>
                    </td>
                    <td>
                        Puedes contactar a través del correo: protecciondatos@quimsaitw.com.
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Datos objeto de tratamiento y procedencia</strong>
                    </td>
                    <td>
                        <p>- Datos de contacto, si los proporcionas (nombre, apellidos, correo electrónico y teléfono).</p>
                        <p>- Nombre y cargo de los personas sobre las que informe en la denuncia, ya sea como implicados o como testigos.</p>
                        <p>- Información sobre los hechos denunciados que pueda contener datos de carácter personal. </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Finalidad del tratamiento y Legitimación</strong>
                    </td>
                    <td>
                        <p>De conformidad con la normativa vigente en materia de protección de datos, la Empresa te informa de que tus datos personales serán recabados y tratados con la finalidad de:</p>
                        <ul>
                            <li>Analizar, procesar e investigar los hechos comunicados de manera confidencial.</li>
                            <li>Gestionar la denuncia realizada.</li>
                            <li>Intercambiar información con el objetivo de complementar la investigación.</li>
                            <li>Entrevistarte para clarifica los hechos comunicados, si procede.</li>
                        </ul>
                        <p>La base jurídica que legitima el tratamiento de los datos de carácter personal facilitados a través del Canal de Ética, Comunicación y Denuncias es el cumplimiento de una obligación legal por la Entidad dentro del marco normativo
                            de protección de datos que regula los canales de denuncias, entre los que se incluyen de los deberes de prevención y control en materia de prevención de responsabilidad penal y cumplimiento normativo aplicables a la Entidad.</p>
                        <p>Cuando el usuario proporcione sus datos de carácter personal de forma voluntaria, será su propio consentimiento la base jurídica que legitima el tratamiento de estos.</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Destinatarios</strong>
                    </td>
                    <td>
                        <p>Los datos serán objeto de cesión a terceros en cumplimiento de una obligación legal, por lo que podrían ser comunicados a los jueces y tribunales en cumplimiento de la legislación vigente. </p>
                        <p>Asimismo, en el curso de una investigación, puede ser necesario que compartamos los hechos que informes con otra sociedad del grupo, si ésta se encuentra implicada en los hechos.</p>
                        <p>No se realizan transferencias internacionales datos a terceros países.</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Criterios de Conservación, Gestión y Custodia</strong>
                    </td>
                    <td>
                        <p>Las comunicaciones recibidas a través del Canal, una vez recibidas, se asignan en un entorno seguro y confidencial, con acceso restringido a las personas responsables del cumplimiento normativo. </p>
                        <p>La información relativa a la comunicación será mantenida, como norma general durante 3 meses. Antes de la finalización de dicho plazo, podrá procederse a su guardado en base a lo que establecen las normativas objeto de la comunicación,
                            y/o por estar una investigación / análisis en curso, y/o para acreditar la diligencia de las partes en la gestión y actuación de la comunicación. </p>
                        <p>Asimismo, se procederá a su eliminación dentro de dicho plazo cuando se considere que la comunicación no debe dar lugar a un proceso de investigación ni se considere necesario guardarla. </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Derechos de los interesados</strong>
                    </td>
                    <td>
                        <p>Usted tiene derecho a obtener confirmación sobre el tratamiento de sus datos de carácter personal por parte de esta entidad. En concreto, usted tiene derecho a acceder a sus datos personales, así como a solicitar la rectificación
                            de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
                        <p>En determinadas circunstancias, usted podrá solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones. </p>
                        <p>En determinadas circunstancias y por motivos relacionados con su situación particular, usted podrá oponerse al tratamiento de sus datos. En este caso, la entidad dejará de tratar sus datos, salvo por motivos legítimos imperiosos,
                            o para el ejercicio o la defensa de posibles reclamaciones.</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Procedimiento para su ejercicio</strong>
                    </td>
                    <td>
                        Las personas afectadas podrán ejercitar y solicitar información sobre sus datos de carácter personal a través del correo electrónico protecciondatos@quimsaitw.com, todo ello sin perjudico de las excepciones y limitaciones con respecto al ejercicio de
                        estos Derechos que establecen determinadas normativas y, en especial la normativa vigente en materia de Prevención de Blanqueo de Capitales, y/o dichos derechos se puedan ver limitados o restringidos en los supuestos de existencia
                        de un interés legítimo para la entidad y siempre y cuando sea necesario para salvaguardar la integridad de la investigación y los hechos denunciados.
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Confidencialidad</strong>
                    </td>
                    <td>
                        La Entidad se compromete a guardar la confidencialidad con respecto a sus datos, y resto de información aportada por usted, así como los datos e información de las personas implicadas aporta por usted al tramitar la denuncia y/o comunicación a través
                        de este Canal externo de Ética, denuncias y comunicación.
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Cookies</strong>
                    </td>
                    <td>
                        La Empresa no emplea cookies para recoger información de las personas usuarias. Únicamente se utilizan cookies propias, de sesión, con finalidad técnica (aquellas que permiten a la persona usuaria la navegación a través de la página web y la utilización
                        de las diferentes opciones y servicios que en ella existen) y de registro.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style="height: 75px;"></div>
</div>