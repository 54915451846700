
import { UsuarioModel } from '../usuario.model/usuario.model';


export class DenunciaModel {

  id: number;
  
  /* 1-Datos*/
  fecha: Date;
  ubicacion: string;
  relacion: string;
  categoria: string;
  /* 2-Detalles*/
  comentado: boolean;
  implicado: string;
  implicadoMas: string; /* Array*/
  testigo: boolean;
  testigoMas: string;/* Array*/
  contenido: string;
  archivo: string;

  idUsuario: UsuarioModel;




  constructor() { }



}
