<div id="encabezado" class="navbar fixed-top">

    <div class="nav justify-content-left" id="encabezadoIzq">
        <a routerLink="/login" style="margin:0; padding:0"><img src="https://i.imgur.com/wHgjlTU.jpg" style="height: 50px; padding-right: 15px;"></a>
        <h1>Canal de denuncias anónimo</h1>
    </div>

</div>
<div style="height: 75px; margin-top:-5px"></div>



<router-outlet></router-outlet>




<footer>
    <div class="text-left" style="float:left">
        Copyright ©2021 NarancoBrands
    </div>
    <a class="text-right" style="float: right;" routerLink="/proteccion-datos">Protección de datos y consentimiento</a>
    <a class="text-right" style="float: right; margin-right: 20px;" routerLink="/terminos-uso">Terminos de uso</a>
    <a class="text-right" style="float: right; margin-right: 20px;" routerLink="/canales-externos">Canales de denuncia externos</a>
    <!-- <div id="google_translate_element" class="nav justify-content-end"style="float:right"></div> -->
</footer>