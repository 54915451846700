<app-pasos></app-pasos>
<div id="pasos">
    <div id="headerPasos">
        <strong>Confirmación</strong>

        <p>Como último paso del proceso debe indicar una clave secreta que solo ud. conocerá. Esta clave, junto al código de identificación que le indicaremos al finalizar serán los únicos medios para poder seguir la evolución de su comunicación. </p>
    </div>
    <div id="bodyPasos">
        <form [formGroup]="denuncia" (ngSubmit)="onSubmit()">
            <div id="formulario" style="margin-left: 30%; margin-right:30%">
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">Clave de acceso</span>
                    <input formControlName="clave_acceso" required type="text" minlength="8" class="form-control" aria-label="Clave" aria-describedby="basic-addon1">
                </div>
                <small class="text-muted">Mínimo 8 carácteres</small>
                <!-- <small class="text-muted">Mínimo 8 carácteres, incluido números y mayúsculas.</small> -->


                <div class="input-group mb-3">
                    <br>
                    <span class="input-group-text" id="basic-addon1">Confirmación clave de acceso</span>
                    <input [formControl]="clave_acceso_confirmacion" type="text" minlength="8" class="form-control" aria-label="Confirmación" aria-describedby="basic-addon1">
                </div>

            </div>
            <input type="checkbox" [formControl]="aceptado" required><span id="asterisco"> He leído el <a class="text-muted" href="#">Aviso sobre
                    protección
                    de datos y
                    consentimiento</a> y acepto el procesamiento de mis datos personales de conformidad con lo dispuesto
                en
                dicho aviso.</span>

            <br><br>
            <!-- Validar contraseña iguales -->
            <button class="btn btn-primary" (click)="anterior()">Anterior</button>

            <button [disabled]="!aceptado.value" type="submit" class="btn btn-primary" id="buttonDcha">Confirmar</button>
            <!-- <button type="submit" class="btn btn-primary" id="buttonDcha" data-bs-dismiss="alert" data-bs-target="#my-alert">Confirmar</button> -->
        </form>
    </div>
</div>
<br>