import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  logout() {
    localStorage.removeItem('userTokenDenuncias');
  }

}
