
import { DenunciaModel } from '../denuncia.model/denuncia.model';



export class UsuarioModel {

  id: number;

  /* 3-Datos*/
  nombre: string;
  apellido: string;
  email: string;
  telefono: string;
  cargo: string;
  provincia: string;
  pais: string;
  anonimo: boolean;
  /* 4-confirmación*/
  clave: string;

  idDenuncia: DenunciaModel;



  constructor() { }

}
