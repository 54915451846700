<div id="containerLogin1">

    <div id="izqLogin">
        <div class="text-center">
            <h2>Términos de uso y condiciones de funcionamiento</h2>
            <hr>
            <div class="text-left">
                <p>
                    En cumplimiento de la Ley de Servicios de la Sociedad de la Información y Comercio electrónico, así como en cumplimiento de la normativa vigente en protección de datos, se informa que el titular de la web https://www.quimsaitw.com (en adelante, “la Web”
                    y del canal de denuncia accesible a través de la misma (en adelante, “el Canal”) es Química Industrial Mediterránea, S.L.U., con CIF B29225687, y domicilio social en Calle Rosa de los Vientos, 75, 29006, Málaga, Andalucía, España (en
                    adelante, “la Empresa”).
                </p>
                <p>
                    El simple acceso, navegación y uso de la Web y del Canal de Denuncia atribuye la condición de usuario del mismo (en adelante, el “Usuario”) e implica la aceptación de la presente cláusula de términos y condiciones. La aceptación del presente documento
                    conlleva que el Usuario:
                </p>
                <ul>
                    <li>ha leído, entiende y comprende lo aquí expuesto</li>
                    <li>asume todas las obligaciones aquí expuestas.</li>
                </ul>
                <p>Las presentes Condiciones de uso tendrán un período de validez indefinido. La Empresa se reserva el derecho a modificarlas y/o actualizarlas en cualquier momento sin previo aviso, por lo que se recomienda revisarlas frecuentemente.</p>
            </div>
        </div>
    </div>
</div>





<div id="containerLogin2">
    <br>
    <div class="text-left">
        <strong>Condiciones para el usuario</strong>
        <br><br>
        <ul>
            <li>Como usuario te obligas a hacer un uso correcto y lícito de la plataforma.</li>
            <li>
                Utilizarás la plataforma para los objetivos que ha sido diseñada, y en ningún caso la utilizarás con objetivos contrarios a la ley.
            </li>
            <li>
                El canal de denuncias no es un servicio de emergencia. En caso de emergencia ponte en contacto con las autoridades locales.
            </li>
            <li>
                Deberás cumplimentar toda la información definida como obligatoria para poder proceder al envío de la misma.
            </li>
            <li>
                Este servicio no está diseñado para presentar información que pueda considerarse secreta o estrictamente confidencial.
            </li>
            <li>
                Reflexiona y revisa la información que envías para asegurar, que si no lo quieres no revela tu identidad.
            </li>
            <li>
                Podrás elegir entre enviar la comunicación indicando tus datos de contacto o sin indicarlos. En ambos casos la plataforma te proporcionará un número de identificación única; es importante que conserves esta identificación, solo con ella podrás consultar
                el estado de la comunicación. Si lo pierdes no podrás volver a acceder a la misma. Por motivos de seguridad y confidencialidad, la Empresa no podrá proporcionar acceso a códigos perdidos.
            </li>
            <li>
                Si la Empresa comprueba que ha habido mala fe al realizar la denuncia, esta se reserva el derecho a tomar acciones legales si el denunciante ha sido identificado.
            </li>
            <li>
                Podrás descargarte un certificado en formato PDF en el que figurará el número de identificador de tu comunicación, así como toda la información que has enviado a la empresa.
            </li>
            <li>
                El Usuario es responsable de la veracidad de la información comunicada, asumiendo las consecuencias de haber actuado de mala fe o enviado información o documentación falsa.
            </li>
            <li>
                El Usuario responde de cualquier daño o perjuicio de cualquier índole que la Empresa pudiera sufrir, directa o indirectamente, como consecuencia de un uso indebido del producto, un incumplimiento de los términos y condiciones de uso o un incumplimiento
                de la ley vigente por tu parte.
            </li>
            <li>
                El Usuario se compromete a utilizar el Canal, exclusivamente, en los términos previsto en los presentes Términos de Uso y Funcionamiento, y a no actuar ni permitir que terceras personas actúen en su nombre para: Hacer y distribuir copias del Canal; Hacer
                y distribuir descargas ilegales del Canal; Intentar copiar, reproducir, alterar o modificar, hacer ingeniería inversa, desarmar, descompilar trasferir, intercambiar, o traducir el Canal; y, en general, a no crear ninguna clase de proyecto
                derivado del Canal.
            </li>
        </ul>
        <hr>
        <strong>Objeto de Canal</strong>
        <br><br>
        <p>
            La Empresa ha implementado y puesto en marcha el presente Canal Externo de Ética Comunicación y Denuncias online que tiene por objeto constituir un mecanismo eficaz para fortalecer nuestro compromiso de comportamiento ético y responsable en las actividades
            y operaciones de esta entidad, a través de la colaboración de todos los empleados, colaboradores, clientes y terceros que actúen en nuestro nombre y/o por su cuenta o beneficio.
        </p>
        <p>
            Este canal tiene por objeto que cualquier persona relacionada con la actividad de la Entidad pueda informar, comunicar o denunciar hechos, irregularidades, riesgos, acciones u omisiones que puedan implicar la comisión de delitos, incumplimientos de las
            normativas y leyes que le son de aplicación a la entidad o conductas contrarias a los protocolos y procedimientos implemente o tenga implementados para garantizar el cumplimiento de dichas normativas, así como el ejercicio de los derechos
            de protección de datos y la comunicación de irregularidades o riesgos en esta materia.
        </p>
        <hr>
        <strong>¿Quiénes deben informar, comunicar o denunciar través del Canal?</strong>
        <br><br>
        <p>
            Cualquier trabajador, administrador, proveedor, colaborador o asesor externo y, en general, cualquier persona o empresa con implicación directa o indirecta con esta entidad, y/o que actúe por cuenta y en beneficio que tenga conocimiento de hechos, irregularidades,
            riesgos, acciones u omisiones que puedan implicar la comisión de delitos, incumplimientos de las normativas y leyes que le son de aplicación a la entidad o conductas contrarias a los protocolos y procedimientos implemente o tenga implementados
            para garantizar el cumplimiento de dichas normativas, incluida la comunicación de posibles incidentes que puedan ser constitutivos de violaciones de seguridad al amparo de lo previsto en la normativa de protección de datos.
        </p>
        <p>
            De igual modo, cualquier persona que tenga relación con a la entidad podrá solicitar los Derechos que le otorga la normativa de protección de datos.
        </p>
        <hr>
        <strong>¿Quiénes pueden ser denunciados a través del Canal de Denuncias?</strong>
        <br><br>
        <p>
            Cualquier otro trabajador, administrador, proveedor, colaborador o asesor externo y, en general, cualquier persona o empresa con implicación directa o indirecta con esta entidad que actúen por cuenta y en beneficio de esta, que haya realizado o cometido
            hechos, irregularidades, riesgos, acciones u omisiones que puedan implicar la comisión de delitos, incumplimientos de las normativas y leyes que le son de aplicación a la entidad o conductas contrarias a los protocolos y procedimientos implemente
            o tenga implementados para garantizar el cumplimiento de dichas normativas.
        </p>
        <hr>
        <strong>¿Qué se puede y debe comunicar o denunciar a través del Canal?</strong>
        <br><br>
        <p>
            Con carácter general, a través del Canal Ética Comunicación y Denuncias cualquier usuario podrá informar sobre los siguientes aspectos
        </p>
        <ul>
            <li>
                Indicios, sospechas o evidencias de posibles incumplimientos normativos
            </li>
            <li>
                Delitos y comportamientos no éticos
            </li>
            <li>
                Incumplimiento de los protocolos, normas y códigos de conducta de la entidad
            </li>
            <li>
                Riesgos o incumplimientos en materia de Protección de datos
            </li>
            <li>
                Solicitudes del ejercicio de los derechos de Protección de Datos
            </li>
        </ul>
        <p>
            En particular cualquier trabajador, administrador, proveedor, colaborador o asesor externo y, en general, cualquier persona o empresa con implicación directa o indirecta con esta entidad, y/o que actúe por cuenta y en beneficio podrá informar, comunicar
            y denunciar cualquier hecho u operación sospechosa, indicio, incidencia, irregularidad, riesgo o conocimiento de posibles delitos en relación con las siguiente materias:
        </p>
        <ul>
            <li>
                Competencia
            </li>
            <li>
                Contratación pública
            </li>
            <li>
                Impuesto sobre sociedades
            </li>
            <li>
                Intereses financieros de la Unión (gastos, recaudación de ingresos y fondos)
            </li>
            <li>
                Prevención del blanqueo de capitales y financiación del terrorismo
            </li>
            <li>
                Protección del medioambiente
            </li>
            <li>
                Protección de los consumidores
            </li>
            <li>
                Protección de los datos personales y la intimidad
            </li>
            <li>
                Salud pública
            </li>
            <li>
                Seguridad de las redes y sistemas de información
            </li>
            <li>
                Seguridad en el transporte
            </li>
            <li>
                Seguridad en los productos
            </li>
            <li>
                Otros, como corrupción; descubrimiento y revelación de secretos, evasión y fraude fiscal; comportamiento no ético, discriminatorio, contrario a la igualdad de las personas y posible acoso laboral y/o sexual.
            </li>
        </ul>
        <p>
            El canal para las violaciones de seguridad está limitada únicamente a la finalidad descrita (comunicación de incidencias o acontecimientos que supongan o puedan suponer violaciones de seguridad), sin que en ningún caso se pueda configurar como el cumplimiento
            de la obligación de documentación de las violaciones seguridad previstas en el artículo 33 del Reglamento Europeo de Protección de Datos 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, previstas para las entidades responsables
            y encargadas del tratamiento.
        </p>
        <hr>
        <strong>Acceso al Canal y Forma de presentación de las comunicaciones y denuncias </strong>
        <br><br>
        <p>
            <strong>Acceso al Canal:</strong> A través de la página web de la Empresa podrá acceder al Canal de Comunicación y Denuncias pinchando el correspondiente enlace, el cual redirigirá al Usuario a la plataforma online que desempeña la función
            de Canal.
        </p>
        <p>
            No se admitirá ninguna comunicación o denuncia que no se efectúe a través de este Canal.
        </p>
        <p>
            <strong>Campos obligatorios: </strong>Para poder formular la denuncia, comunicación y/o petición del Usuario deberá cumplimentar una serie de datos y paramentos relativos a la misma.
        </p>
        <p>
            Si el Usuario no rellena todos paramentos requeridos la aplicación informática no le permitirá efectuar la comunicación, denuncia o solicitud.
        </p>
        <p>
            <strong>Identificación: </strong>En garantía del anonimato y protección del Denunciante, el Usuario podrá elegir entre enviar la comunicación indicando sus datos de identificación y contacto o sin indicarlos.
        </p>
        <p>
            Para poder solicitar cualquier petición o consulta sobre los Derechos de Acceso, Rectificación, Supervisión o limitación con respecto a sus datos de carácter personal, la identificación es requisito indispensable para poder tramitar su solicitud. Estas
            solicitudes se dirigirán al correo electrónico protecciondatos@quimsaitw.com., de conformidad con la Política de Privacidad disponible para su consulta en este Canal y en la Web de la Empresa.
        </p>
        <hr>
        <strong>Procedimiento de tramitación de las denuncias</strong>
        <br><br>
        <p>
            <strong>Recepción de las denuncias. </strong>Las denuncias que se comuniquen a través de esta Canal serán recibidas con carácter general, por el Comité de Ética y Compliance, que será el órgano responsable de la gestión del Canal de Denuncias
            de la Empresa.
        </p>
        <p>
            La investigación preliminar de la denuncia será llevada a cabo por dos miembros del Comité de Cumplimiento que no podrán estar implicados en los hechos denunciados y que será designados a tal efecto por el Comité de Cumplimiento.
        </p>
        <p>
            En este análisis preliminar, los dos miembros del Comité de Cumplimiento valorarán los indicios de la infracción denunciada y, en consecuencia, decidirán sobre la conveniencia de iniciar o no una investigación.
        </p>
        <p>
            Si la denuncia no cumple con los requisitos de forma exigidos o los hechos denunciados no suponen una infracción de las previstas en el ámbito objetivo del Canal de Denuncias, se denegará su admisión a trámite y los datos contenidos en ella se eliminarán
            de manera inmediata.
        </p>
        <p>
            En cambio, cuando la denuncia formulada cumpla con los requisitos de forma exigidos y, además, los hechos denunciados se encuentren comprendidos en el ámbito objetivo del Canal de Denuncias, se acordará su admisión a trámite.
        </p>
        <p>
            Cualquier persona que haya sido objeto de denuncia será informada con carácter inmediato sobre:
        </p>
        <ul>
            <li type="i">la recepción de la denuncia;</li>
            <li type="i">el hecho del que se le acusa;</li>
            <li type="i">los departamentos y terceros que podrán ser informados de la denuncia; y</li>
            <li type="i">cómo ejercitar sus derechos de acceso, rectificación, cancelación y oposición, de conformidad con la normativa de protección de datos.</li>
        </ul>
        <p>
            No obstante lo anterior, si a juicio del Comité de Ética y Compliance existe riesgo de que la notificación al denunciado comprometa la investigación, dicha comunicación podrá aplazarse hasta que el citado riesgo desaparezca. En todo caso, el plazo para
            informar al denunciado no excederá de un (1) mes desde que se haya recibido la denuncia, con la posibilidad de extender dicho plazo a un máximo de tres (3) meses si existen razones justificadas para ello. Todo ello sin perjuicio de que, a
            la vista de las circunstancias concretas, la ley establezca la obligación de observar un plazo superior.
        </p>
        <p>
            <strong>Investigación de los hechos denunciados. </strong>Admitida a trámite la denuncia, el Comité de Ética y Compliance delegará en dos de sus miembros que no estén implicados en los hechos denunciados, la realización de las oportunas investigaciones
            para comprobar la veracidad y realidad de los hechos denunciados.
        </p>
        <p>
            <strong>Propuesta de resolución. </strong>Una vez concluida la investigación, y en función de los resultados obtenidos, los miembros del Comité de Ética y Compliance responsables de la misma formularán una propuesta motivada sobre si procede
            el archivo de la denuncia o, en su defecto, sobre la posible sanción a aplicar. Dicha propuesta deberá ser ratificada por el Comité de Ética y Compliance, pudiendo introducir cuantas modificaciones considere oportunas.
        </p>
        <p>
            El Comité de Ética y Compliance tiene un plazo máximo de tres (3) meses desde la recepción de la denuncia para ratificar la propuesta de resolución.
        </p>
        <p>
            En este sentido, el Comité de Ética y Compliance acordará el archivo de la denuncia y de las actuaciones realizadas cuando no hayan quedado suficientemente acreditados los hechos denunciados, o éstos no sean constitutivos de una infracción incluida en
            el ámbito objetivo del Canal de Denuncias.
        </p>
        <p>
            Por el contrario, si los hechos denunciados han quedado suficientemente acreditados y, además, son constitutivos de una infracción incluida en el ámbito objetivo del Canal de Denuncias, el Comité de Ética y Compliance adoptará la decisión, debidamente
            justificada, de las medidas disciplinarias a aplicar, debiendo poner esta decisión en conocimiento del órgano de dirección de la Empresa.
        </p>
        <p>
            <strong>Ejecución de la sanción. </strong>Corresponderá aplicar la sanción acordada en cada caso al Departamento de Recursos Humanos para el caso de que el infractor fuere un empleado, o bien, en el resto de los casos, al Departamento con
            el que el denunciado mantenga la relación contractual.
        </p>
        <hr>
        <strong>Garantías del proceso de comunicación o denuncias</strong>
        <br><br>
        <p>
            La Empresa ha puesto en funcionamiento este Canal de Ética, denuncias y comunicación, asegurando las siguientes garantías fundamentales al proceso de comunicación / denuncia:
        </p>
        <ul>
            <li>
                <strong>Confidencialidad y Protección de Datos: </strong>configuración del canal y la gestión de este bajo los principios de confidencialidad, protección de la información y cumplimiento de las más altas medidas de protección de datos
                personales, según la normativa aplicable en materia de protección de datos de carácter personal.
            </li>
            <li>
                <strong>Conflictos de Interés e Independencia: </strong>Protocolo interno para gestionar y controlar los posibles conflictos de interés y/o falta de independencia de las comunicaciones / denuncias recibidas
            </li>
            <li>
                <strong>Protección del denunciante/ comunicante: </strong>Protocolo interno de gestión para preservar la protección del denunciante.
            </li>
            <li>
                <strong>Prohibición de represalias: </strong>Los denunciantes que, de buena fe, comuniquen la presunta comisión de una conducta comprendida en el ámbito de aplicación del Canal de Denuncias estarán protegidos contra cualquier tipo de represalia,
                discriminación y penalización por motivo de las denuncias realizadas; si bien, la presente prohibición de represalias no impedirá la adopción de las medidas disciplinarias que procedan cuando la investigación interna determine que la comunicación
                es falsa y que la persona que la ha realizado es consciente de su falsedad.
            </li>
            <li>
                <strong>Profesionalidad y Experiencia: </strong>Profesionales expertos en cumplimiento normativo para un tratamiento y gestión adecuado de las comunicaciones / denuncias, así como para preservar tanto los derechos del denunciante como
                del denunciado por hechos infundados o sin el principio de buena fe.
            </li>
            <li>
                <strong>Registro: </strong>se elabora un registro de las comunicaciones / denuncias como garantía de su tratamiento, gestión y no alteración de forma independiente y sin conflictos de interés.
            </li>
        </ul>
    </div>
    <div style="height: 75px;"></div>
</div>