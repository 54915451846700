import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders,  HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {GLOBAL} from './global';
import { Observable } from 'rxjs';

@Injectable()
export class ServicioDenunciaService {

  public url: string;

  // public headers = new HttpHeaders().set('http_apikey', '156f38df-24e1-4ee2-8e2a-03f900615a5a');
  public headers = new HttpHeaders({'http_apikey': '156f38df-24e1-4ee2-8e2a-03f900615a5a'});


  constructor(public _http: HttpClient){
    this.url = GLOBAL.url;
  }

  // -------------------------------------------------------------
  // PRUEBAS
  prueba() {
    console.log(this.headers);
    // return this._http.get(this.url + '/pruebas', {headers: this.headers});
    let data = {
      dni:"12345678A",
      codigo: "111111aaaaaa",
      gasto: 9.12,
      categoria: "sorteo"
    };
    return this._http.post('http://localhost/bonos-navidad/index.php/transferencias/add', data);
  }

  // -------------------------------------------------------------
  // Login
  login(username, password) {
    // return this._http.get(this.url + `/login/check/${username}/${password}`);
    return this._http.get(this.url + `/login/check/${username}/${password}`, {headers: this.headers});
  }

  checkToken(token){
    return this._http.get(this.url + `/login/checkToken/${token}`, {headers: this.headers});
  }


  // -------------------------------------------------------------
  // Denuncias
  addDenuncia(denuncia) {
    return this._http.post(this.url + '/denuncias/add', denuncia, {headers: this.headers});
  }

  updateDenuncia(denuncia){
    return this._http.post(this.url + '/denuncias/update', denuncia, {headers: this.headers});
  }

  updateDenunciaCE(denuncia){
    return this._http.post(this.url + '/denuncias/updateCE', denuncia, {headers: this.headers});
  }

  getDenuncias() {
    return this._http.get(this.url + '/denuncias/search', {headers: this.headers});
  }

  getDenunciaById(id) {
    return this._http.get(this.url + '/denuncias/search/' + id, {headers: this.headers});
  }

  getDenunciaByCodigo(codigo) {
    return this._http.get(this.url + '/denuncias/searchCodigo/' + codigo, {headers: this.headers});
  }

  // ---------------------------------------------------------------
  // Implicados
  addImplicado(implicado) {
    return this._http.post(this.url + '/implicados/add', implicado, {headers: this.headers});
  }

  getImplicado(id){
    return this._http.get(this.url + '/implicados/search/' + id, {headers: this.headers});
  }

  // ----------------------------------------------------------------
  // Testigos
  addTestigo(testigo) {
    return this._http.post(this.url + '/testigos/add', testigo, {headers: this.headers});
  }

  getTestigo(id){
    return this._http.get(this.url + '/testigos/search/' + id, {headers: this.headers});
  }

  // --------------------------------------------------------------
  // Detalles extra
  addDetalle(detalle) {
    return this._http.post(this.url + '/detalles/add', detalle, {headers: this.headers});
  }

  getDetalles(id_denuncia){
    return this._http.get(this.url + `/detalles/search/${id_denuncia}`, {headers: this.headers});
  }

  // --------------------------------------------------------------
  // Notas
  addNota(nota) {
    return this._http.post(this.url + '/notas/add', nota, {headers: this.headers});
  }

  getNotas(id_denuncia){
    return this._http.get(this.url + `/notas/search/${id_denuncia}`, {headers: this.headers});
  }

  // --------------------------------------------------------------
  // Archivos
  makeFileRequest(File): Observable<any>{
    const fd = new FormData();
    for(let i=0; i<File.length; i++){
        fd.append('uploads1[]', File[i], File[i].name);
    }

    return this._http.post(this.url + '/owned/images', fd, {headers: this.headers});
  }

  addArchivo(archivo) {
    return this._http.post(this.url + '/archivos/add', archivo, {headers: this.headers});
  }

  getArchivos(){
    return this._http.get(this.url + '/archivos/search', {headers: this.headers});
  }

  getArchivosById(id){
    return this._http.get(this.url + `/archivos/search/${id}`, {headers: this.headers});
  }

  getArchivosResolucionById(id){
    return this._http.get(this.url + `/archivos/searchResolucion/${id}`, {headers: this.headers});
  }

  // --------------------------------------------------------------
  // Actualizaciones
  addActualizacion(actualizacion){
    return this._http.post(this.url + '/actualizaciones/add', actualizacion, {headers: this.headers});
  }

  getActualizaciones() {
    return this._http.get(this.url + '/actualizaciones/search', {headers: this.headers});
  }

  // --------------------------------------------------------------
  // Email
  sendEmail(id) {
    return this._http.post(this.url + '/email/nuevaDenuncia', {id: id}, {headers: this.headers});
  }

}
